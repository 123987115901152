import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { changeProgressBarOnScroll } from "../components/utils/index"
import OptInForm from "../components/optinform"
import AddToCart from "../components/carts/AddToCart"

export default class Goodbye2020Page extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "November Sale")
  }

  render() {
    return (
      <Layout>
        <div id="november-lp">
          <ProgressBar />
          <HeadMeta metadata={pageData.metadata} />
          <section
            className="hero"
            id='goodbye-2020-banner'
          >
            <img
              src={withPrefix("/img/goodbye-2020/lp-banner.png")}
              alt="Goodbye 2020"
              className="w-100 d-none d-md-block"
            />
            <img
              src={withPrefix("/img/goodbye-2020/lp-banner-mobile.png")}
              alt="Goodbye 2020"
              className="w-100 d-block d-md-none"
            />
          </section>
          <section className="mb-0 py-5" style={{backgroundColor:'#053300'}}>
            <div className="container text-white">
              <div className="row align-items-center">
                  <div className='col-md-4'>
                    <img
                      src={withPrefix("/img/goodbye-2020/lp-photo.png")}
                      alt="Girl with Present"
                      className="img-fluid"
                    />
                  </div>
                  <div className='col-md-6 offset-md-1' style={{color: '#E0AC6E'}}>
                    <p>It's been the longest year  yet, but it's time to say goodbye.</p>
                    <p>Let's wrap up 2020 with a joyful heart and welcome the new year with a healthier you!</p>
                  </div>
              </div>
            </div>
          </section>
          <section id="promotion" className="promotion cta" style={{backgroundColor: '#AA0000', backgroundImage:`url('/img/goodbye-2020/lp-snowflake.png')`}}>
            <div className="container">
              <h2 className='text-white text-center'>The Gift of Health For Your Loved Ones</h2>
              <div className="row py-5">
                <div className="col-lg-4 mb-5">
                  <div className="card mb-5">
                    <div className="card-body text-center d-flex flex-column">
                      <img
                        className="img-fluid px-lg-5 mb-4"
                        src={withPrefix("/img/product/personal-box.png")}
                        alt="DNA Explorer Personal Box"
                      />
                      <img
                        className="img-fluid px-lg-5 mb-4"
                        src={withPrefix("img/logo/DNA-Explorer-Personal.png")}
                        alt="DNA Explorer Personal"
                      />
                      <div className="text-center">
                        <h5><span className="badge text-white badge-pill px-3 py-1" style={{backgroundColor: ' #7A0000'}}>Personalised DNA report</span></h5>
                      </div>
                      <p className='mt-3'>
                        Select from 8 report categories and customize your own report
                      </p>
                      <div className="price-cta mt-auto mb-4">
                        <div className="row align-items-center my-4">
                          <div className="col-12 align-item-center">
                            <span style={{ fontSize: "1.3rem" }}>from</span>
                            <span className="h2 ml-2">RM 179</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='px-3 align-item-center w-100' style={{position:'absolute', bottom: '-35px'}}>
                      <div className='row mx-3 py-3 align-items-center' style={{backgroundColor:'#E2A54B', borderRadius: '0.5rem'}}>
                        <div className='col-2 px-2'>
                          <img
                            className="img-fluid"
                            src={withPrefix("img/goodbye-2020/giftbox.svg")}
                            alt="DNA Explorer Prime"
                          />
                        </div>
                        <div className='col-10'>
                          <p className='small mb-0' style={{lineHeight:'1.0', color:'#7A0000'}}>
                            Get a FREE <b>ABO&ME Health Screening Standard Package</b> (worth RM79) with every purchase
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    href="/products/dna-explorer-personal"
                    className="btn btn-white btn-block mb-3"
                  >
                    Buy now
                  </a>
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="card mb-5 ">
                    <div className="card-body text-center d-flex flex-column">
                      <img
                        className="img-fluid px-lg-5 mb-4"
                        src={withPrefix("/img/product/prime-box.png")}
                        alt="DNA Explorer Prime Box"
                      />
                      <img
                        className="img-fluid px-lg-5 mb-4"
                        src={withPrefix("img/logo/DNA-Explorer-Prime.png")}
                        alt="DNA Explorer Prime"
                      />
                      <div className="text-center">
                        <h5><span className="badge text-white badge-pill px-3 py-1" style={{backgroundColor: ' #7A0000'}}>Our most comprehensive package</span></h5>
                      </div>
                      <p className='mt-3'>
                        121 reports on Health Risks, Fitness, Nutrition & Diet, Skin, and more
                      </p>
                      <div className="price-cta mt-auto mb-4">
                        <div className="row align-items-center my-4">
                          <div className="col-12 align-item-center">
                            <strike style={{ fontSize: "1.3rem" }}>RM1599</strike>
                            <span className="h2 ml-2">RM 1299</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='px-3 align-item-center w-100' style={{position:'absolute', bottom: '-35px'}}>
                      <div className='row mx-3 py-3 align-items-center' style={{backgroundColor:'#E2A54B', borderRadius: '0.5rem'}}>
                        <div className='col-2 px-2'>
                          <img
                            className="img-fluid"
                            src={withPrefix("img/goodbye-2020/giftbox.svg")}
                            alt="DNA Explorer Prime"
                          />
                        </div>
                        <div className='col-10'>
                          <p className='small mb-0' style={{lineHeight:'1.0', color:'#7A0000'}}>
                            Get a FREE <b>ABO&ME Health Screening Life Package</b> (worth RM249) with every purchase
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddToCart
                    buttonName="Add to cart"
                    price="1299"
                    id="34"
                    class="btn-white btn-block"
                    alaCarte="false"
                  />
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="card mb-5 ">
                    <div className="card-body text-center d-flex flex-column">
                      <img
                        className="img-fluid px-lg-5 mb-4"
                        src={withPrefix("/img/product/carrier-box.png")}
                        alt="DNA Explorer Carrier Box"
                      />
                      <img
                        className="img-fluid px-lg-5 mb-4"
                        src={withPrefix("img/logo/DNA-Explorer-Carrier.png")}
                        alt="DNA Explorer Carrier"
                      />
                      <div className="text-center">
                        <h5><span className="badge text-white badge-pill px-3 py-1" style={{backgroundColor: ' #7A0000'}}>Pre-marital DNA testing</span></h5>
                      </div>
                      <p className='mt-3'>
                        Discover your carrier status in 13 recessive genetic disorders
                      </p>
                      <div className="price-cta mt-auto mb-4">
                        <div className="row align-items-center my-4">
                          <div className="col-12 align-item-center">
                            <strike style={{ fontSize: "1.3rem" }}>RM1299</strike>
                            <span className="h2 ml-2">RM 999</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='px-3 align-item-center w-100' style={{position:'absolute', bottom: '-35px'}}>
                      <div className='row mx-3 py-3 align-items-center' style={{backgroundColor:'#E2A54B', borderRadius: '0.5rem'}}>
                        <div className='col-2 px-2'>
                          <img
                            className="img-fluid"
                            src={withPrefix("img/goodbye-2020/giftbox.svg")}
                            alt="DNA Explorer Prime"
                          />
                        </div>
                        <div className='col-10'>
                          <p className='small mb-0' style={{lineHeight:'1.0', color:'#7A0000'}}>
                            Get a FREE <b>ABO&ME Health Screening Premium Package</b> (worth RM149) with every purchase
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddToCart
                    buttonName="Add to cart"
                    price="999"
                    id="35"
                    class="btn-white btn-block"
                    alaCarte="false"
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="covidna-banner" style={{backgroundPosition: 'center'}}>
            <div className="container">
              <div className="row my-lg-5">
                <div className="col-lg-4 offset-lg-8 text-center content py-4">
                  <img src={withPrefix('img/covidna/covidna-title.png')} className="img-fluid" alt="CoviDNA" style={{maxHeight: '200px'}}/>
                  <Link
                    to={withPrefix("/covidna")}
                    className="btn btn-dark px-5 mt-5"
                  >
                    <strong>I Want In!</strong>
                  </Link>
                </div>  
              </div>
            </div>
          </section>
          <OptInForm
            opt_in_form={pageData.opt_in_form}
            background_color="#053300"
            btn_color = "#E2A54B"
          />
          <section id="terms-condition" className="py-5" style={{backgroundColor: '#AA0000'}}>
            <div className="container">
              <div className="row align-items-center my-2">
                <div className="col-md-11 offset-md-1 text-center text-white">
                  <h3>Terms and Conditions</h3>
                  <div className="text-left mt-5">
                    <ul>
                      <li>This promotion is valid is valid from 15/12/2020-3/1/2021.</li>
                      <li>This promotion is valid for new customers only.</li>
                      <li>This promotion is valid for DNA Explorer Prime, DNA Explorer Carrier, and DNA Explorer Personal.</li>
                      <li>First-time customers will be subject to a one-time lab handling fee of RM400 for DNA Explorer Personal.</li>
                      <li>This promotion is valid for DNA Explorer Personal purchases of 3 reports or more only.</li>
                      <li>This promotion is not applicable with other discounts and promotions.</li>
                      <li>Advanx Health reserves the right to amend the Terms & Conditions of this promotion at any time without prior notice.</li>
                      <li>
                        The complimentary Health Screening packages are redeemable on specific dates and locations as selected by Advanx Health only. 
                        Dates and blood draw location will be announced on a later date. 
                        On-site blood draws on other dates may be arranged with additional charges.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "Goodbye 2020 - Test Negative and Stay Positive In The New Year| Advanx Health",
    description: "Get a free health screening with every DNA kit you purchase! It's been a rough year - reward yourself by taking better preventive care of your health today.",
    image: "https://www.advanxhealth.com/img/goodbye-2020/lp-banner.png",
    url: "https://www.advanxhealth.com/goodbye-2020",
  },
  opt_in_form: {
    header: "Say Hello To 2021 With Better Health!",
    description: "Subscribe for more healthcare tips, facts, news, and more!",
    form_name: "home-newsletter",
    form_action: "/",
    form_interest: "newsletter",
    form_submit: "Subscribe",
    form_funnel_id: "9841248",
    mailchimp_group_id: "db8b07b00c",
    mailchimp_group_list_id: "14c5755ee7",
    mailchimp_tag_id: "27845",
  },
}
